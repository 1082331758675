import * as SOURCE from "three";
import * as React from "react";
import { useRef, useEffect, useState } from "react";

// Imported NavigationControl - you may not need - map
import { NavigationControl } from "react-map-gl";

import { Interaction } from "three.interaction";
import { OrbitControls } from "three/addons/controls/OrbitControls.js";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";

import {
  setHealth,
  playerStats,
  playerWin,
  playerLose,
  updateStatsUi,
  calculatePlayerDamage,
  startSpinAnimation,
  checkHit,
  checkPlayerClick,
  encounterMob,
  encounterRenderer,
  encounterControls,
  encounterCamera,
  setEncounterInfo,
  getEncounterInfo,
  playerDamageIndicate,
  encounterInfo,
} from "./MapQuest";

let playerTurn = true;

export function getPlayerTurn() {
  return playerTurn;
}
export function setPlayerTurn(turn) {
  playerTurn = turn;
}
export function playerHit() {
  if (checkHit()) {
    // startSpinAnimation("player");
    // setEncounterInfo("enemyHealth", getEncounterInfo("enemyHealth") - 10);
    // encounterInfo.enemyHealth -= calculatePlayerDamage();

    encounterInfo.health -= 10;
    // if (getEncounterInfo("enemyHealth") <= 0) {
    //   // startSpinAnimation("player");
    //   setTimeout(function () {
    //     playerWin();
    //   }, 1000);
    // }
  } else {
    playerStats.health -= 10;
    startSpinAnimation(encounterMob);
  }

  updateStatsUi();

  setTimeout(function () {
    if (playerStats.health <= 0) {
      playerLose();
    }
    if (encounterInfo.health <= 0) {
      playerWin();
    }
  }, 1000);

  // setTimeout(function () {
  //

  //   let playerHealth = playerStats.health;
  //   playerHealth -= 10;
  //   setHealth(playerHealth);
  //   playerTurn = true;
  // }, 1000);
}

export function loadCameraEncounter() {
  var video = document.getElementById("userVideo");
  video.setAttribute("playsinline", "");
  video.setAttribute("autoplay", "");
  video.setAttribute("muted", "");

  /* Setting up the constraint */
  var facingMode = "environment"; // Can be 'user' or 'environment' to access back or front camera (NEAT!)
  var constraints = {
    audio: false,
    video: {
      facingMode: facingMode,
    },
  };

  /* Stream it to video element */
  navigator.mediaDevices
    .getUserMedia(constraints)
    .then(function success(stream) {
      video.srcObject = stream;
    });
}

export function stopEncounterCamera() {
  const video = document.getElementById("userVideo");

  // A video's MediaStream object is available through its srcObject attribute
  const mediaStream = video.srcObject;

  // Through the MediaStream, you can get the MediaStreamTracks with getTracks():
  const tracks = mediaStream.getTracks();

  // Tracks are returned as an array, so if you know you only have one, you can stop it with:
  tracks[0].stop();
}

export function Encounter() {
  React.useEffect(() => {
    var reticleElm = document.getElementsByTagName("encounter-reticle")[0];

    reticleElm.removeEventListener("click", playerHit);
    reticleElm.addEventListener("click", playerHit);
  });

  return (
    <encounter-container id="encounter">
      <video id="userVideo"></video>
      <canvas id="encounterCanvas"></canvas>

      <encounter-ui>
        {/* <encounter-btn id="leave-encounter">Leave</encounter-btn> */}
        <encounter-reticle></encounter-reticle>
        <encounter-hit-area id="hit-area">
          <encounter-hit-bar id="hit-bar"></encounter-hit-bar>
        </encounter-hit-area>
      </encounter-ui>
      <player-info>
        <health-container id="healthInfo"></health-container>
        <health-container id="enemyHealth"></health-container>
      </player-info>
    </encounter-container>
  );
}
