import React, { useState, useEffect } from "react";
import "./Weather.css";

const Weather = () => {
  const [weather, setWeather] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const apiKey = "dea886ab571fa9ba5defd3fe2cb73358";
    const defaultCity = "Barcelona";

    const fetchWeatherByLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            fetchWeatherData(latitude, longitude, apiKey);
          },
          (error) => {
            console.error("Error getting location:", error);
            if (error.code === error.PERMISSION_DENIED) {
              fetchWeatherData(null, null, apiKey, defaultCity);
              setError("Location permission denied");
            } else {
              setError("Error getting location");
            }
          }
        );
      } else {
        fetchWeatherData(null, null, apiKey, defaultCity);
        setError("Geolocation is not supported");
      }
    };

    const fetchWeatherData = (latitude, longitude, apiKey, city = null) => {
      let url;
      if (city) {
        url = `https://api.openweathermap.org/data/2.5/weather?q=${city}&appid=${apiKey}&units=metric`;
      } else {
        url = `https://api.openweathermap.org/data/2.5/weather?lat=${latitude}&lon=${longitude}&appid=${apiKey}&units=metric`;
      }

      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          setWeather(data);
          setError(null); // Clear the error when weather data is fetched successfully
        })
        .catch((error) => {
          console.error("Error fetching weather data:", error);
          setError("Failed to fetch weather data");
        });
    };

    fetchWeatherByLocation();
  }, []);

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  return (
    <div className="weather-component">
      {error && <p>{error}</p>}
      {weather && (
        <>
          <button className="weather-icon-button" onClick={toggleDetails}>
            <img
              src={`https://openweathermap.org/img/w/${weather.weather[0].icon}.png`}
              alt={weather.weather[0].description}
            />
          </button>
          {showDetails && (
            <div className="weather-details">
              <p>
                {weather.name}, {weather.sys.country}
              </p>
              {/* <p>{weather.main.temp}°C</p> */}
              <p>{Math.round(weather.main.temp)}°C</p>
              {error && <p>{error}</p>}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Weather;
