import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// import SignInSignUp from "../Pages/SignInSignUp/SignInSignUp";

// import icons from icons
import Icons from "../img/Icons";

import "./Navbar.css";

import getSignedIn from "../Pages/SignInSignUp/SignInSignUp";
import { initializeApp } from "firebase/app";
import { loadPageSignUp } from "../Pages/SignInSignUp/SignInSignUp";

export function updateNav(signedIn) {
  console.log("UPDATE NAV", signedIn);

  if (signedIn) {
    document.getElementById("NavParent").classList.remove("navHide");
  } else {
    document.getElementById("NavParent").classList.add("navHide");
  }
}

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);

    // Close the menu after 20 seconds if it's opened
    if (!isOpen) {
      setTimeout(() => {
        setIsOpen(false); // Close the menu
      }, 20000); // 20 seconds in milliseconds
    }
  };
  // --------------------------

  useEffect(() => {
    let loggedIn =
      sessionStorage.getItem("signedIn") &&
      sessionStorage.getItem("signedIn") !== "false";

    updateNav(loggedIn);
    // loadPageSignUp();
    // -----------------

    // Clean up function to clear timeout if component unmounts
    return () => {
      clearTimeout(); // Clear any existing timeout
    };
    // -----------------
  }, []);

  return (
    <div className="main" id="NavParent">
      {/* <a href="https://arhive.co/" target="_blank" rel="noopener noreferrer">
          <img src={Icons.iconLogo} className="logo" alt="Logo" />
        </a> */}
      <div className="menu">
        <img
          src={Icons.icon3line}
          className={`menu__trigger ${isOpen ? "is-rotate" : ""}`}
          id="btn"
          onClick={toggleMenu}
          alt="Menu Trigger"
        />

        <Link to="/">
          <img
            src={Icons.iconHome}
            className={`menu__item menu__item--0 ${isOpen ? "item-0" : ""}`}
            alt="Home"
          />
        </Link>

        <Link to="/about">
          <img
            src={Icons.iconInfo}
            className={`menu__item menu__item--1 ${isOpen ? "item-1" : ""}`}
            alt="About"
          />
        </Link>
        <Link to="/mapQuest">
          <img
            src={Icons.iconMap}
            className={`menu__item menu__item--2 ${isOpen ? "item-2" : ""}`}
            alt="Map"
          />
        </Link>
        {/* <Link to="/contact">
          <img
            src={Icons.iconEmail}
            className={`menu__item menu__item--3 ${isOpen ? "item-3" : ""}`}
            alt="Contact"
          />
        </Link> */}
        <Link to="/SignInSignUp">
          <img
            src={Icons.iconPerson}
            className={`menu__item menu__item--4 ${isOpen ? "item-4" : ""}`}
            alt="Sign In/Sign Up"
          />
        </Link>
      </div>
    </div>
  );
};

export default Navbar;
