import signModel from "../MapQuest/models/DC_Sign2.gltf";
import dogModel from "../MapQuest/models/Dog.gltf";
import clockModel from "../MapQuest/models/CS_Clock.gltf";
import spindleModel from "../MapQuest/models/AWspindle.gltf";
import leafModel from "../MapQuest/models/LEAF 1.gltf";
import flowerSpindleModel from "../MapQuest/models//FlowerSpindle.gltf";
import helmetModel from "../MapQuest/models//helmet.gltf";

import signImg from "../MapQuest/models/DC_Sign2.png";
import dogImg from "../MapQuest/models/Dog.png";
import clockImg from "../MapQuest/models/CS_Clock.png";
import spindleImg from "../MapQuest/models/AWspindle.png";
import leafImg from "../MapQuest/models/LEAF 1.png";
import flowerSpindleImg from "../MapQuest/models/FlowerSpindle.png";
import helmetImg from "../MapQuest/models//helmet.png";

import { randInt } from "../MapQuest/MapQuest";

function hasItem(items, item) {
  return items.split(",").includes(item);
}
export let entities = {
  sign: {
    model: signModel,
    icon: signImg,
    health: 20,

    description: "protects against any canine enemies",

    start: function (items) {
      if (hasItem(items, "dog")) {
        this.movement.jumpTime = 5;
      }
    },
    movement: {
      randrange: 60,
      previousTimeStep: 0,
      pointTarget: { x: 0, y: 0, z: 0 },
      pointStart: { x: 0, y: 0, z: 0 },
      origin: { x: 0, y: 0, z: -5 },
      jumpTime: 1,
      weakItems: "dog",

      pattern: function (timeStep, currentPosition) {
        let offset = { x: 0, y: 0, z: 0 };

        let lastSecond = Math.floor(this.previousTimeStep / 1000);
        let thisSecond = Math.floor(timeStep / 1000);

        // offset.x = (Math.sin(timeStep/400)/2) * 60

        if (thisSecond - lastSecond >= this.jumpTime) {
          this.pointTarget = {
            x: randInt(-this.randrange, this.randrange),
            y: randInt(-this.randrange, this.randrange),
            z: randInt(-this.randrange, this.randrange),
          };
          this.pointStart = {
            x: currentPosition.x,
            y: currentPosition.y,
            z: currentPosition.z,
          };

          this.previousTimeStep = timeStep;
        }

        var dx = currentPosition.x - this.pointStart.x;
        var dy = currentPosition.y - this.pointStart.y;
        var dz = currentPosition.z - this.pointStart.z;

        let distanceToPoint = Math.sqrt(dx * dx + dy * dy + dz * dz);

        let ax = this.pointStart.x - this.pointTarget.x;
        let ay = this.pointStart.y - this.pointTarget.y;
        let az = this.pointStart.z - this.pointTarget.z;

        let totalDistance = Math.sqrt(ax * ax + ay * ay + az * az);
        // let progress = ( distanceToPoint) / totalDistance;

        let progress = (timeStep - Math.floor(timeStep / 1000) * 1000) / 1000;

        offset.x =
          currentPosition.x + (this.pointTarget.x - currentPosition.x) / 20;
        offset.y =
          this.origin.y +
          (thisSecond - lastSecond <= 0 ? Math.sin(progress * Math.PI) * 5 : 0);
        offset.z =
          currentPosition.z + (this.pointTarget.z - currentPosition.z) / 20;

        offset.x += this.origin.x;
        offset.y += this.origin.y;
        offset.z += this.origin.z;

        return offset;
      },
    },
  },
  dog: {
    model: dogModel,
    icon: dogImg,
    health: 30,

    description: "protects against any feline enemies",

    start: function (items) {
      if (hasItem(items, "lion")) {
        this.movement.jumpTime = 5;
      }
    },
    movement: {
      randrange: 40,
      previousTimeStep: 0,
      pointTarget: { x: 0, y: 0, z: 0 },
      pointStart: { x: 0, y: 0, z: 0 },
      origin: { x: 0, y: 0, z: -5 },
      jumpTime: 0.1,
      weakItems: "dog",

      pattern: function (timeStep, currentPosition) {
        let offset = { x: 0, y: 0, z: 0 };

        let lastSecond = Math.floor(this.previousTimeStep / 1000);
        let thisSecond = Math.floor(timeStep / 1000);

        // offset.x = (Math.sin(timeStep/400)/2) * 60

        if (thisSecond - lastSecond >= this.jumpTime) {
          this.pointTarget = {
            x: randInt(-this.randrange, this.randrange),
            y: randInt(-this.randrange, this.randrange),
            z: randInt(-this.randrange, this.randrange),
          };
          this.pointStart = {
            x: currentPosition.x,
            y: currentPosition.y,
            z: currentPosition.z,
          };

          this.previousTimeStep = timeStep;
        }

        var dx = currentPosition.x - this.pointStart.x;
        var dy = currentPosition.y - this.pointStart.y;
        var dz = currentPosition.z - this.pointStart.z;

        let distanceToPoint = Math.sqrt(dx * dx + dy * dy + dz * dz);

        let ax = this.pointStart.x - this.pointTarget.x;
        let ay = this.pointStart.y - this.pointTarget.y;
        let az = this.pointStart.z - this.pointTarget.z;

        let totalDistance = Math.sqrt(ax * ax + ay * ay + az * az);
        // let progress = ( distanceToPoint) / totalDistance;

        let progress = (timeStep - Math.floor(timeStep / 1000) * 1000) / 1000;

        offset.x =
          currentPosition.x + (this.pointTarget.x - currentPosition.x) / 20;
        offset.y =
          this.origin.y +
          (thisSecond - lastSecond <= 0
            ? Math.sin(progress * Math.PI) * 40
            : 0);
        offset.z =
          currentPosition.z + (this.pointTarget.z - currentPosition.z) / 20;

        offset.x += this.origin.x;
        offset.y += this.origin.y;
        offset.z += this.origin.z;

        return offset;
      },
    },
  },
  clock: {
    model: clockModel,
    icon: clockImg,
    health: 10,
    description: "slows down other clocks you might find",

    start: function (items) {
      if (hasItem(items, "clock")) {
        this.movement.jumpTime = 5;
      }
    },

    movement: {
      randrange: 40,
      previousTimeStep: 0,
      pointTarget: { x: 0, y: 0, z: 0 },
      pointStart: { x: 0, y: 0, z: 0 },
      origin: { x: 0, y: 0, z: -5 },
      jumpTime: 1,
      weakItems: "dog",

      pattern: function (timeStep, currentPosition) {
        let offset = { x: 0, y: 0, z: 0 };

        let lastSecond = Math.floor(this.previousTimeStep / 1000);
        let thisSecond = Math.floor(timeStep / 1000);

        // offset.x = (Math.sin(timeStep/400)/2) * 60

        if (thisSecond - lastSecond >= this.jumpTime) {
          this.pointTarget = {
            x: randInt(-this.randrange, this.randrange),
            y: randInt(-this.randrange, this.randrange),
            z: randInt(-this.randrange, this.randrange),
          };
          this.pointStart = {
            x: currentPosition.x,
            y: currentPosition.y,
            z: currentPosition.z,
          };

          this.previousTimeStep = timeStep;
        }

        var dx = currentPosition.x - this.pointStart.x;
        var dy = currentPosition.y - this.pointStart.y;
        var dz = currentPosition.z - this.pointStart.z;

        let distanceToPoint = Math.sqrt(dx * dx + dy * dy + dz * dz);

        let ax = this.pointStart.x - this.pointTarget.x;
        let ay = this.pointStart.y - this.pointTarget.y;
        let az = this.pointStart.z - this.pointTarget.z;

        let totalDistance = Math.sqrt(ax * ax + ay * ay + az * az);
        // let progress = ( distanceToPoint) / totalDistance;

        let progress = (timeStep - Math.floor(timeStep / 1000) * 1000) / 1000;

        offset.x =
          currentPosition.x + (this.pointTarget.x - currentPosition.x) / 2;
        offset.y =
          this.origin.y +
          (thisSecond - lastSecond <= 0 ? Math.sin(progress * Math.PI) * 0 : 0);
        offset.z =
          currentPosition.z + (this.pointTarget.z - currentPosition.z) / 2;

        offset.x += this.origin.x;
        offset.y += this.origin.y;
        offset.z += this.origin.z;

        return offset;
      },
    },
  },
  spindle: {
    model: spindleModel,
    icon: spindleImg,
    health: 30,
    description: "protects against any nature enemies",

    start: function (items) {
      if (
        hasItem(items, "spindle") ||
        hasItem(items, "leaf") ||
        hasItem(items, "flowerspindle")
      ) {
        this.movement.jumpTime = 5;
      }
    },
    movement: {
      randrange: 40,
      previousTimeStep: 0,
      pointTarget: { x: 0, y: 0, z: 0 },
      pointStart: { x: 0, y: 0, z: 0 },
      origin: { x: 0, y: 0, z: -5 },
      jumpTime: 1,
      weakItems: "dog",

      pattern: function (timeStep, currentPosition) {
        let offset = { x: 0, y: 0, z: 0 };

        let lastSecond = Math.floor(this.previousTimeStep / 1000);
        let thisSecond = Math.floor(timeStep / 1000);

        // offset.x = (Math.sin(timeStep/400)/2) * 60

        if (thisSecond - lastSecond >= this.jumpTime) {
          this.pointTarget = {
            x: randInt(-this.randrange, this.randrange),
            y: randInt(-this.randrange, this.randrange),
            z: randInt(-this.randrange, this.randrange),
          };
          this.pointStart = {
            x: currentPosition.x,
            y: currentPosition.y,
            z: currentPosition.z,
          };

          this.previousTimeStep = timeStep;
        }

        var dx = currentPosition.x - this.pointStart.x;
        var dy = currentPosition.y - this.pointStart.y;
        var dz = currentPosition.z - this.pointStart.z;

        let distanceToPoint = Math.sqrt(dx * dx + dy * dy + dz * dz);

        let ax = this.pointStart.x - this.pointTarget.x;
        let ay = this.pointStart.y - this.pointTarget.y;
        let az = this.pointStart.z - this.pointTarget.z;

        let totalDistance = Math.sqrt(ax * ax + ay * ay + az * az);
        // let progress = ( distanceToPoint) / totalDistance;

        let progress = (timeStep - Math.floor(timeStep / 1000) * 1000) / 1000;

        offset.x =
          currentPosition.x +
          (this.pointTarget.x - currentPosition.x) / 20 +
          (thisSecond - lastSecond <= 0
            ? Math.sin(progress * Math.PI) * 5 - 2.5
            : 0);
        offset.y =
          this.origin.y +
          (thisSecond - lastSecond <= 0 ? Math.sin(progress * Math.PI) * 0 : 0);
        offset.z =
          currentPosition.z + (this.pointTarget.z - currentPosition.z) / 20;

        offset.x += this.origin.x;
        offset.y += this.origin.y;
        offset.z += this.origin.z;

        return offset;
      },
    },
  },
  leaf: {
    model: leafModel,
    icon: leafImg,
    health: 30,
    description: "protects against any nature enemies",

    start: function (items) {
      if (
        hasItem(items, "spindle") ||
        hasItem(items, "leaf") ||
        hasItem(items, "flowerspindle")
      ) {
        this.movement.jumpTime = 5;
      }
    },
    movement: {
      randrange: 40,
      previousTimeStep: 0,
      lastTimeChange: 0,
      pointTarget: { x: 0, y: 0, z: 0 },
      pointStart: { x: 0, y: 0, z: 0 },
      origin: { x: 0, y: 0, z: -5 },
      jumpTime: 5,
      weakItems: "dog",

      pattern: function (timeStep, currentPosition) {
        let offset = { x: 0, y: 0, z: 0 };

        let lastSecond = Math.floor(this.previousTimeStep / 1000);
        let thisSecond = Math.floor(timeStep / 1000);

        // offset.x = (Math.sin(timeStep/400)/2) * 60

        if (thisSecond - lastSecond >= this.jumpTime) {
          this.pointTarget = {
            x: randInt(-this.randrange, this.randrange),
            y: randInt(-this.randrange, this.randrange),
            z: randInt(-this.randrange, this.randrange),
          };
          this.pointStart = {
            x: currentPosition.x,
            y: currentPosition.y,
            z: currentPosition.z,
          };

          this.previousTimeStep = timeStep;
        }

        var dx = currentPosition.x - this.pointStart.x;
        var dy = currentPosition.y - this.pointStart.y;
        var dz = currentPosition.z - this.pointStart.z;

        let distanceToPoint = Math.sqrt(dx * dx + dy * dy + dz * dz);

        let ax = this.pointStart.x - this.pointTarget.x;
        let ay = this.pointStart.y - this.pointTarget.y;
        let az = this.pointStart.z - this.pointTarget.z;

        let totalDistance = Math.sqrt(ax * ax + ay * ay + az * az);
        // let progress = ( distanceToPoint) / totalDistance;

        let progress =
          (timeStep - this.previousTimeStep) / (this.jumpTime * 1000);
        console.log("PROGRESS: ", progress);

        offset.x =
          currentPosition.x + (this.pointTarget.x - currentPosition.x) / 20;
        offset.y = this.origin.y + Math.tan(-1 * progress * Math.PI) * 5;
        offset.z =
          currentPosition.z + (this.pointTarget.z - currentPosition.z) / 20;

        offset.x += this.origin.x;
        offset.y += this.origin.y;
        offset.z += this.origin.z;

        return offset;
      },
    },
  },
  flowerspindle: {
    model: flowerSpindleModel,
    icon: flowerSpindleImg,
    health: 30,
    description: "protects against any nature enemies",

    start: function (items) {
      if (
        hasItem(items, "spindle") ||
        hasItem(items, "leaf") ||
        hasItem(items, "flowerspindle")
      ) {
        this.movement.jumpTime = 5;
      }
    },
    collectable: true,
    movement: {
      randrange: 40,
      previousTimeStep: 0,
      pointTarget: { x: 0, y: 0, z: 0 },
      pointStart: { x: 0, y: 0, z: 0 },
      origin: { x: 0, y: 0, z: -5 },
      jumpTime: 1,
      weakItems: "dog",

      pattern: function (timeStep, currentPosition) {
        let offset = { x: 0, y: 0, z: 0 };

        let lastSecond = Math.floor(this.previousTimeStep / 1000);
        let thisSecond = Math.floor(timeStep / 1000);

        // offset.x = (Math.sin(timeStep/400)/2) * 60

        if (thisSecond - lastSecond >= this.jumpTime) {
          this.pointTarget = {
            x: randInt(-this.randrange, this.randrange),
            y: randInt(-this.randrange, this.randrange),
            z: randInt(-this.randrange, this.randrange),
          };
          this.pointStart = {
            x: currentPosition.x,
            y: currentPosition.y,
            z: currentPosition.z,
          };

          this.previousTimeStep = timeStep;
        }

        var dx = currentPosition.x - this.pointStart.x;
        var dy = currentPosition.y - this.pointStart.y;
        var dz = currentPosition.z - this.pointStart.z;

        let distanceToPoint = Math.sqrt(dx * dx + dy * dy + dz * dz);

        let ax = this.pointStart.x - this.pointTarget.x;
        let ay = this.pointStart.y - this.pointTarget.y;
        let az = this.pointStart.z - this.pointTarget.z;

        let totalDistance = Math.sqrt(ax * ax + ay * ay + az * az);
        // let progress = ( distanceToPoint) / totalDistance;

        let progress = (timeStep - Math.floor(timeStep / 1000) * 1000) / 1000;

        offset.x =
          currentPosition.x +
          (this.pointTarget.x - currentPosition.x) / 20 +
          (thisSecond - lastSecond <= 0
            ? Math.sin(progress * Math.PI) * 5 - 2.5
            : 0);
        offset.y =
          this.origin.y +
          (thisSecond - lastSecond <= 0 ? Math.sin(progress * Math.PI) * 0 : 0);
        offset.z =
          currentPosition.z +
          (this.pointTarget.z - currentPosition.z) / 20 +
          (thisSecond - lastSecond <= 0
            ? Math.sin(progress * Math.PI) * 5 - 2.5
            : 0);

        offset.x += this.origin.x;
        offset.y += this.origin.y;
        offset.z += this.origin.z;

        return offset;
      },
    },
  },
  // helmet: {
  //   model: helmetModel,
  //   icon: helmetImg,
  //   health: 30,
  //   description: "protects against any nature enemies",

  //   start: function (items) {
  //     if (
  //       hasItem(items, "spindle") ||
  //       hasItem(items, "leaf") ||
  //       hasItem(items, "flowerspindle")
  //     ) {
  //       this.movement.jumpTime = 5;
  //     }
  //   },
  //   collectable: true,
  //   movement: {
  //     randrange: 40,
  //     previousTimeStep: 0,
  //     pointTarget: { x: 0, y: 0, z: 0 },
  //     pointStart: { x: 0, y: 0, z: 0 },
  //     origin: { x: 0, y: 0, z: -5 },
  //     jumpTime: 1,
  //     weakItems: "dog",

  //     pattern: function (timeStep, currentPosition) {
  //       let offset = { x: 0, y: 0, z: 0 };

  //       let lastSecond = Math.floor(this.previousTimeStep / 1000);
  //       let thisSecond = Math.floor(timeStep / 1000);

  //       // offset.x = (Math.sin(timeStep/400)/2) * 60

  //       if (thisSecond - lastSecond >= this.jumpTime) {
  //         this.pointTarget = {
  //           x: randInt(-this.randrange, this.randrange),
  //           y: randInt(-this.randrange, this.randrange),
  //           z: randInt(-this.randrange, this.randrange),
  //         };
  //         this.pointStart = {
  //           x: currentPosition.x,
  //           y: currentPosition.y,
  //           z: currentPosition.z,
  //         };

  //         this.previousTimeStep = timeStep;
  //       }

  //       var dx = currentPosition.x - this.pointStart.x;
  //       var dy = currentPosition.y - this.pointStart.y;
  //       var dz = currentPosition.z - this.pointStart.z;

  //       let distanceToPoint = Math.sqrt(dx * dx + dy * dy + dz * dz);

  //       let ax = this.pointStart.x - this.pointTarget.x;
  //       let ay = this.pointStart.y - this.pointTarget.y;
  //       let az = this.pointStart.z - this.pointTarget.z;

  //       let totalDistance = Math.sqrt(ax * ax + ay * ay + az * az);
  //       // let progress = ( distanceToPoint) / totalDistance;

  //       let progress = (timeStep - Math.floor(timeStep / 1000) * 1000) / 1000;

  //       offset.x =
  //         currentPosition.x +
  //         (this.pointTarget.x - currentPosition.x) / 20 +
  //         (thisSecond - lastSecond <= 0
  //           ? Math.sin(progress * Math.PI) * 5 - 2.5
  //           : 0);
  //       offset.y =
  //         this.origin.y +
  //         (thisSecond - lastSecond <= 0 ? Math.sin(progress * Math.PI) * 0 : 0);
  //       offset.z =
  //         currentPosition.z +
  //         (this.pointTarget.z - currentPosition.z) / 20 +
  //         (thisSecond - lastSecond <= 0
  //           ? Math.sin(progress * Math.PI) * 5 - 2.5
  //           : 0);

  //       offset.x += this.origin.x;
  //       offset.y += this.origin.y;
  //       offset.z += this.origin.z;

  //       return offset;
  //     },
  //   },
  // },
};

export let items = {
  Dog: {
    description: "protects against any feline enemies",
  },
};
