import * as React from "react";
import { HomePageCard } from "./HomePageCard";

import WeatherComponent from "../Weather/Weather"; // Import the weather component

import "./Home.css";
import Icons from "../../img/Icons";

export const Home = () => {
  return (
    <div className="homeContainer">
      <div
        className="card-background"
        style={{ backgroundImage: `url(${Icons.iconBackground})` }}
      >
        <div className="cardP homeTitle">
          <img src={Icons.iconSQLogo} class="Card__logo" alt="Game logo" />

          <h1 className="Home_header SignUp_Card__text-gameName">
            Street Quest <span class="SignUp_Card__text-SubTitle"> ™</span>
          </h1>
          {/* <h2 className="tradeMark SignUp_Card__text-SubTitle">TM</h2> */}
        </div>
      </div>
      <WeatherComponent />
    </div>
  );
};
