// import { closeItems, openItems } from "./MapQuest";
import React from "react";
import "./Pedometer.css";

import { update, getDatabase, ref, onValue } from "firebase/database";
import { playerStats } from "./MapQuest";
import { getSignedIn } from "../SignInSignUp/SignInSignUp";
import { getPlayerTag } from "./MapQuest";

export function updatePedoMeter(steps, load = false) {
  if (document.getElementById("pedometerInfo") == null) return;
  document.getElementById("pedometerInfo").innerHTML = `${Math.floor(
    steps
  )} steps`;
  if (getSignedIn() && !load) {
    const db = getDatabase();
    update(ref(db, `players/${getPlayerTag()}`), {
      steps: steps,
    });
  }
}

export function loadSteps() {
  const db = getDatabase();

  let tagString = `players/${getPlayerTag()}`;

  const playerRef = ref(db, tagString);

  console.log("TAG STRING", tagString);

  onValue(playerRef, (snapshot) => {
    console.log("IN VAL");
    const data = snapshot.val();

    playerStats.walkDist = data.steps;

    console.log("GOT STEPS", data.steps);
    updatePedoMeter(playerStats.walkDist, true);
  });
}

export function Pedometer() {
  return <pedometer-butn id="pedometerInfo">/ Steps</pedometer-butn>;
}
