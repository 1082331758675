// import { closeItems, openItems } from "./MapQuest";
import React from "react";

import chestTop from "../MapQuest/models/Treasure Chest_top.gltf";

import chestBody from "../MapQuest/models/Treasure Chest_body.gltf";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
import "./Inventory.css";
import * as SOURCE from "three";

import { OrbitControls } from "three/addons/controls/OrbitControls.js";
import { degToRad } from "three/src/math/MathUtils.js";
import { items } from "./entities";
import { entities } from "./entities";
import { setCookies } from "./MapQuest";
import { getPlayerTag } from "./MapQuest";

import {
  getDatabase,
  ref,
  set,
  update,
  get,
  onValue,
  remove,
} from "firebase/database";

var chestScene;
var chestCanvas;
var chestRenderer;

var chestModel = new SOURCE.Scene();
let chestCamera = new SOURCE.PerspectiveCamera(10, 60 / 60, 0.0001, 1000);
let chestControls;
let degreeChestOpen = 35;

function loadItemPopup(name) {
  document.getElementById("item-popup-container").classList.remove("hide");
  // document.getElementById("item-popup").innerHTML = items[name].description;

  document.getElementsByTagName("item-name")[0].innerHTML = name;
  if (entities[name] == undefined) return;

  if (entities[name].description) {
    document.getElementsByTagName("item-description")[0].innerHTML =
      entities[name].description;
  } else {
    document.getElementsByTagName("item-description")[0].innerHTML = "";
  }

  // alert(entities[name].icon);
  if (entities[name].icon) {
    document.getElementById("item-image").src = entities[name].icon;
  }
}

let item3Ds = [];

export function loadInventory() {
  const db = getDatabase();
  const playerRef = ref(db, `players/${getPlayerTag()}`);

  onValue(playerRef, (snapshot) => {
    const data = snapshot.val();

    let foundItems;
    if (data == null || data.items == null) {
      foundItems = "";
    } else {
      foundItems = data.items;
    }

    console.log("FOUND ITEMS: ", foundItems);
    setItems(foundItems);
  });
}
export function updateInventory(playerItems) {
  let itemNames = playerItems.split(",");

  if (document.getElementById("inventory")) {
    document.getElementById("inventory").innerHTML = "";
  }

  if (playerItems == "") return;

  for (let thisName in itemNames) {
    let newItem = document.createElement("inventory-item");
    newItem.classList.add("item-received");

    // if (itemNames[thisName].toLowerCase() in items) {
    //   newItem.innerHTML = items[itemNames[thisName].toLowerCase()].description;
    // } else {
    //   newItem.innerHTML = itemNames[thisName];
    // }

    console.log("THIS NAME ITEM", itemNames[thisName]);

    let newIcon = document.createElement("img");
    newIcon.classList.add("item-scene");

    let itemType = itemNames[thisName].toLowerCase();

    if (entities[itemType]) {
      newIcon.src = entities[itemType].icon;
    }

    newItem.append(newIcon);

    document.getElementById("inventory").append(newItem);

    newItem.addEventListener("click", function () {
      loadItemPopup(itemType);
    });
  }
}

export function renderChest() {
  if (chestRenderer) {
    // console.log("RENDER CHEST");
    chestRenderer.render(chestScene, chestCamera);
    chestControls.update();
  }

  for (var item of item3Ds) {
    if (item.renderer) {
      item.renderer.render(item.scene, item.camera);
    }
  }
}

console.log("CLOAD", document.cookie);

export function setItems(itemString, load = false) {
  sessionStorage.setItem("items", itemString);
}

export function Inventory() {
  // setItems("Dog,lion,clock,spindle,leaf,flowerSpindle");

  console.log("COOOKI", document.cookie);

  let itemsOpen = false;

  let chestLidPart;

  function closeItems() {
    // if (!itemsOpen) return;
    // itemsOpen = false;
    chestLidPart.rotateZ(degToRad(-degreeChestOpen));
    document
      .getElementsByTagName("item-container")[0]
      .classList.remove("container-expanded");
    document.getElementById("inventory").scroll(0, 0);
  }
  function openItems() {
    // if (itemsOpen) return;
    // itemsOpen = true;
    chestLidPart.rotateZ(degToRad(degreeChestOpen));
    document
      .getElementsByTagName("item-container")[0]
      .classList.add("container-expanded");

    console.log("ITEMS,", sessionStorage.getItem("items"));

    updateInventory(sessionStorage.getItem("items"));
  }

  React.useEffect(function () {
    const inventory = document.getElementById("chest-elm");
    inventory.onmousedown = function (e) {
      e.preventDefault();
      e.stopPropagation();
      itemsOpen = !itemsOpen;

      if (itemsOpen) {
        openItems();
      } else {
        closeItems();
      }

      // openItems();
    };

    // const closeInventory = document.getElementsByTagName("close-container")[0];
    // closeInventory.addEventListener("mousedown", function (e) {
    //   e.preventDefault();
    //   e.stopPropagation();

    //   closeItems();
    // });

    chestScene = new SOURCE.Scene();
    chestCanvas = document.getElementById("chest-elm");

    const ambientLight = new SOURCE.AmbientLight(0xffffff, 512);
    chestScene.add(ambientLight);

    chestRenderer = new SOURCE.WebGLRenderer({
      antialias: true,
      canvas: chestCanvas,
      alpha: true,
    });

    chestControls = new OrbitControls(chestCamera, chestRenderer.domElement);

    // chestCamera.position.y = -5;
    // chestCamera.position.z = -2;

    chestCamera.rotateZ = 0;
    chestCamera.rotateY = 0;
    chestCamera.rotateX = 0;

    const chestGroup = new SOURCE.Group();

    const loader = new GLTFLoader();
    loader.load(chestTop, (gltf) => {
      chestLidPart = gltf.scene.clone();
      chestGroup.add(chestLidPart);
    });
    const loader2 = new GLTFLoader();
    loader2.load(chestBody, (gltf) => {
      chestGroup.add(gltf.scene.clone());
    });

    chestScene.add(chestGroup);
    chestGroup.position.z = -105;
    chestGroup.position.x = 3;
    // chestGroup.rotateY(degToRad(45));
    // chestGroup.rotateX(degToRad(45));
    chestGroup.rotateY(degToRad(180 + 45));

    const itemPopupBack = document.getElementById("item-popup-container");
    itemPopupBack.addEventListener("click", function (e) {
      this.classList.add("hide");
      // e.stopPropagation()
    });

    // closeItems();
  });

  return (
    <inventory-elms>
      <item-container>
        {/* <close-container> X </close-container> */}
        <canvas id="chest-elm"></canvas>
        <item-inventory id="inventory"></item-inventory>
      </item-container>
      <item-popup-back id="item-popup-container" class="hide">
        <item-popup id="item-popup">
          <img id="item-image"></img>
          <item-name></item-name>
          <item-description></item-description>
        </item-popup>
      </item-popup-back>
    </inventory-elms>
  );
}
