// import * as SOURCE from "../../node_modules/three/build/three.module.js"

import * as SOURCE from "three";
import * as React from "react";
import { useRef, useEffect, useState } from "react";

// Imported NavigationControl - you may not need - map
import { NavigationControl } from "react-map-gl";

import { Interaction } from "three.interaction";
import { OrbitControls } from "three/addons/controls/OrbitControls.js";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";

import streetQuest from "./street-quest.gltf";
import playerBase from "./playerBase.gltf";
import playerHandLeft from "./playerHandLeft.gltf";
import playerHandRight from "./playerHandRight.gltf";
import helmetModel from "../MapQuest/models//helmet.gltf";

import heart from "./models/heart.png";
import playerIcon from "./models/playerIcon.png";
import "mapbox-gl/dist/mapbox-gl.css";
import mapboxgl from "mapbox-gl";
import "./Game.css";

import { Encounter } from "./Encounter";
import { Pedometer, updatePedoMeter, loadSteps } from "./Pedometer";
import { Inventory, updateInventory } from "./Inventory";

import { renderChest } from "./Inventory";
import {
  getDatabase,
  ref,
  set,
  update,
  get,
  onValue,
  remove,
} from "firebase/database";
// Import the functions you need from the SDKs you need

import { getAnalytics } from "firebase/analytics";

import { entities, items } from "./entities";
import {
  getPlayerTurn,
  setPlayerTurn,
  loadCameraEncounter,
  stopEncounterCamera,
} from "./Encounter";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// const analytics = getAnalytics(app);
// Get Image

mapboxgl.accessToken =
  "pk.eyJ1IjoianVkaXRocmlja2V0dHMiLCJhIjoiY2w1djA1azNzMDU0bjNicDd0b3V2NWsycyJ9.ZryKzXNWjBVroFwM5f5-Xg";

let origin = { x: -10, y: 20 };

function getPix(x, y, imageData) {
  var index = 4 * (x + y * imageData.width);

  var red = imageData.data[index]; // red   color
  var green = imageData.data[index + 1]; // green color
  var blue = imageData.data[index + 2]; // blue  color
  var alpha = imageData.data[index + 3];

  return [red, green, blue, alpha];
}

const block = new SOURCE.BoxGeometry(1, 1, 1);

const blockMat = new SOURCE.MeshStandardMaterial({ color: `rgb(0, 0, 0)` });
let modelOrigin = [-0.15283301046573883, 50.825023956653];

let modelAltitude = 0;
let modelRotate = [Math.PI / 2, 0, 0];
let modelAsMercatorCoordinate = mapboxgl.MercatorCoordinate.fromLngLat(
  modelOrigin,
  modelAltitude
);
let modelTransform = {
  translateX: modelAsMercatorCoordinate.x,
  translateY: modelAsMercatorCoordinate.y,
  translateZ: modelAsMercatorCoordinate.z,
  rotateX: modelRotate[0],
  rotateY: modelRotate[1],
  rotateZ: modelRotate[2],
  /* Since the 3D model is in real world meters, a scale transform needs to be
   * applied since the CustomLayerInterface expects units in MercatorCoordinates.
   */
  scale: modelAsMercatorCoordinate.meterInMercatorCoordinateUnits(),
};

let playerOrigin = [-0.15283301046573883, 50.825023956653];
let playerAltitude = 0;
let playerRotate = [Math.PI / 2, 0, 0];
let playerAsMercatorCoordinate = mapboxgl.MercatorCoordinate.fromLngLat(
  playerOrigin,
  playerAltitude
);
let playerTransform = {
  translateX: playerAsMercatorCoordinate.x,
  translateY: playerAsMercatorCoordinate.y,
  translateZ: playerAsMercatorCoordinate.z,
  rotateX: playerRotate[0],
  rotateY: playerRotate[1],
  rotateZ: playerRotate[2],
  /* Since the 3D player is in real world meters, a scale transform needs to be
   * applied since the CustomLayerInterface expects units in MercatorCoordinates.
   */
  scale: playerAsMercatorCoordinate.meterInMercatorCoordinateUnits(),
};

function updatePlayerTransform() {
  playerAltitude = 0;
  playerRotate = [Math.PI / 2, 0, 0];

  playerAsMercatorCoordinate = mapboxgl.MercatorCoordinate.fromLngLat(
    playerOrigin,
    playerAltitude
  );

  // transformation parameters to position, rotate and scale the 3D player onto the map
  playerTransform = {
    translateX: playerAsMercatorCoordinate.x,
    translateY: playerAsMercatorCoordinate.y,
    translateZ: playerAsMercatorCoordinate.z,
    rotateX: playerRotate[0],
    rotateY: playerRotate[1],
    rotateZ: playerRotate[2],
    /* Since the 3D player is in real world meters, a scale transform needs to be
     * applied since the CustomLayerInterface expects units in MercatorCoordinates.
     */
    scale: playerAsMercatorCoordinate.meterInMercatorCoordinateUnits(),
  };
}

var firstLoad = true;

let mapGlobal;

var mapTimer;

function loadMap(mapVar) {
  mapGlobal = mapVar;
}
function addMarkerSync(id, long, lat, type = undefined) {
  const db = getDatabase();

  if (type) {
    update(ref(db, "markers/" + id), {
      long: long,
      lat: lat,
      type: type,
    });
  } else {
    update(ref(db, "markers/" + id), {
      long: long,
      lat: lat,
    });
  }
}
function populateMap() {
  for (let i = 0; i < 50; i++) {
    let range = 500;

    let newCoord = [
      50.82144184830019 + SOURCE.MathUtils.randInt(-range, range) * 0.00001,
      -0.15006820239463078 + SOURCE.MathUtils.randInt(-range, range) * 0.00001,
    ];

    var keys = Object.keys(entities);

    addMarkerSync(
      "marker-" + i,
      newCoord[0],
      newCoord[1],
      keys[(keys.length * Math.random()) << 0]
    );
  }
}
let map = {};
let geolocate = null;
let markers = [];
var playerObjects = [];

var sceneLayer = {};

export let playerStats = {};

let minDistance = 250;

let first = false;

function addRandomEntity() {
  let range = 500;

  if (playerStats == undefined || playerStats.location == undefined) return;

  let newCoord = [
    playerStats.location[1] + SOURCE.MathUtils.randInt(-range, range) * 0.00001,
    playerStats.location[0] + SOURCE.MathUtils.randInt(-range, range) * 0.00001,
  ];

  while (
    measure(
      playerStats.location[1],
      playerStats.location[0],
      newCoord[0],
      newCoord[1]
    ) < minDistance
  ) {
    newCoord = [
      playerStats.location[1] +
        SOURCE.MathUtils.randInt(-range, range) * 0.00001,
      playerStats.location[0] +
        SOURCE.MathUtils.randInt(-range, range) * 0.00001,
    ];
  }

  var keys = Object.keys(entities);

  addMarkerSync(
    "marker-" + (markers.length + 1),
    newCoord[0],
    newCoord[1],
    keys[(keys.length * Math.random()) << 0]
  );
}

async function getRoute(start, end) {
  // make a directions request using cycling profile
  // an arbitrary start will always be the same
  // only the end or destination will change

  const routePoints = {
    start: start,
    end: end,
  };

  const query = await fetch(
    `https://api.mapbox.com/directions/v5/mapbox/cycling/${routePoints.start[0]},${routePoints.start[1]};${routePoints.end[0]},${routePoints.end[1]}?steps=true&geometries=geojson&access_token=${mapboxgl.accessToken}`,
    { method: "GET" }
  );
  const json = await query.json();
  const data = json.routes[0];

  const route = data.geometry.coordinates;
  console.log("ROUTE");
  console.log(json);
  const geojson = {
    type: "Feature",
    properties: {},
    geometry: {
      type: "LineString",
      coordinates: route,
    },
  };
  // if the route already exists on the map, we'll reset it using setData
  if (map.current.getSource("route")) {
    map.current.getSource("route").setData(geojson);
  }
  // otherwise, we'll make a new request
  else {
    map.current.addLayer({
      id: "route",
      type: "line",
      source: {
        type: "geojson",
        data: geojson,
      },
      layout: {
        "line-join": "round",
        "line-cap": "round",
      },
      paint: {
        "line-color": "white",
        "line-width": 5,
        "line-opacity": 1,
      },
    });
  }
}

let mapAreaRange = 1000;
let numEntitesNear = 40;

function gameUpdate() {
  if (!startedAnimations) return;

  if (markers.length != 0) {
    const d = new Date();

    timeProgress = d.getTime() - timeStart;

    for (let i = 0; i < markers.length; i++) {
      if (
        markers[i] == encounterMob
        // sceneLayer[markers[i]].scene.position == undefined
      )
        continue;

      sceneLayer[markers[i]].scene.position.y =
        markerYOffset + Math.sin(timeProgress / 1000.0 + i) * 3;
      if (
        sceneLayer[markers[i]].info != undefined &&
        sceneLayer[markers[i]].info.selected
      ) {
        sceneLayer[markers[i]].scene.position.y += 30;
        sceneLayer[markers[i]].scene.rotation.y += 0.01;
      } else {
        sceneLayer[markers[i]].scene.rotation.y += 0.005;
      }
    }
  }

  encounterUpdate();
}

function measure(lat1, lon1, lat2, lon2) {
  // generally used geo measurement function
  var R = 6378.137; // Radius of earth in KM
  var dLat = (lat2 * Math.PI) / 180 - (lat1 * Math.PI) / 180;
  var dLon = (lon2 * Math.PI) / 180 - (lon1 * Math.PI) / 180;
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((lat1 * Math.PI) / 180) *
      Math.cos((lat2 * Math.PI) / 180) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c;
  return d * 1000; // meters
}

export async function setEncounter(showing = true, entityInfo = {}, entity) {
  console.log("IN SET ENCOUNTER");
  if (showing) {
    console.log("E1");
    getGyro();
    if (inEncounter) return;

    console.log("E2");
    inEncounter = true;

    loadCameraEncounter();

    document.getElementById("encounter").classList.add("show");

    console.log("IN ENCOUNTER", entityInfo, entityInfo.health);

    playerStats.health = 30;

    encounterInfo = entityInfo;
    encounterInfo.health = entityInfo.health;
    inEncounter = showing;
    inCombat = true;
    setPlayerTurn(true);

    sceneLayer[entity].scene.name = entity;
    encounterScene.add(sceneLayer[entity].scene);
    encounterMob = entity;

    if (entityInfo.start) {
      entityInfo.start(playerStats.items.toLowerCase());
    }

    encounterCamera.position.z = 60;
    encounterCamera.position.y = 70;
    sceneLayer[entity].scene.position.z = 60;
    sceneLayer[entity].scene.rotation.set(0, 0, 0);
    encounterCamera.origionalRot = { x: degreesToRadians(-45), y: 0, z: 0 };

    let clonedEntity = sceneLayer[entity].scene.clone();
    reticleEntity = clonedEntity;

    encounterCamera.add(clonedEntity);

    clonedEntity.position.set(0, 0, -70);
    clonedEntity.rotation.set(0, 0, 0);
    encounterScene.add(encounterCamera);

    console.log("CLONED", clonedEntity);

    updateStatsUi();

    // encounterCamera.add(sceneLayer["player"].scene);
    // sceneLayer["player"].scene.position.set(0, -40, -50);
    // sceneLayer["player"].scene.scale.set(0.5, 0.5, 0.5);
    // sceneLayer["player"].scene.rotation.set(0, degreesToRadians(90), 0);
    // mesh.renderOrder = zindex || 999;
    // mesh.material.depthTest = false;
    // mesh.material.depthWrite = false;
    // mesh.onBeforeRender = function (renderer) { renderer.clearDepth(); };

    // encounterCamera.rotat
    // addModel(entityInfo.model,encounterScene,encounterMob)
  } else {
    document.getElementById("encounter").classList.remove("show");
    inEncounter = showing;

    stopEncounterCamera();

    for (var i = encounterScene.children.length - 1; i >= 0; i--) {
      let obj = encounterScene.children[i];
      console.log("DELETING OBJ ", obj);
      encounterScene.remove(obj);
    }

    // encounterCamera.remove(sceneLayer["player"].scene);
    // sceneLayer["player"].scene.scale.set(1, 1, 1);

    // sceneLayer["player"].scene.position.set(0, 0, 0);
    // sceneLayer["player"].scene.rotation.set(0, 0, 0);
  }
}

function setOpacity(obj, opacity) {
  obj.traverse((child) => {
    if (child instanceof SOURCE.Mesh) {
      child.material = child.material.clone();
      child.material.transparent = true;
      child.material.format = SOURCE.RGBAFormat;
      child.material.opacity = opacity;

      child.renderOrder = 999;
      child.material.depthTest = false;
      child.material.depthWrite = false;
      child.onBeforeRender = function (renderer) {
        renderer.clearDepth();
      };
      // console.log("SET OPACITY HERE");
    }
  });
}
let distanceInteract = 100; //100 normal
function getMarkersOn(coords, distance = 10) {
  let markersTouching = [];
  for (let i = 0; i < markers.length; i++) {
    let distance = measure(
      sceneLayer[markers[i]].origin[0],
      sceneLayer[markers[i]].origin[1],
      coords[0],
      coords[1]
    );
    if (distance <= distanceInteract) {
      markersTouching.push(markers[i]);
    }
  }
  return markersTouching;
}

function getNearestMarker(coords) {
  let marker = "";
  let distSmallest = 1000000000000;
  for (let i = 0; i < markers.length; i++) {
    let distance = measure(
      sceneLayer[markers[i]].origin[0],
      sceneLayer[markers[i]].origin[1],
      coords[0],
      coords[1]
    );
    if (distance <= distSmallest) {
      marker = markers[i];
      distSmallest = distance;
    }
  }
  return { name: marker, dist: distSmallest };
}

function loadUserStats() {
  let cookies = getCookies(document.cookie);
  console.log("COOKIES: ", cookies);
  playerStats.health = cookies["health"];

  playerStats.items = cookies["items"] ? cookies["items"] : "";
}

function getPlayerID() {}

export function getPlayerTag() {
  // return `${sessionStorage.getItem("signedIn")}-${sessionStorage.getItem(
  //   "id"
  // )}`;

  return sessionStorage.getItem("id");
}

export async function addItem(item) {
  let itemsStr = sessionStorage.getItem("items");

  itemsStr += "," + item;

  if (itemsStr.charAt(0) == ",") {
    itemsStr = itemsStr.slice(1, itemsStr.length);
  }

  const db = getDatabase();
  const playerRef = ref(db, `players/${getPlayerTag()}`);

  console.log("NEW ITEM STRING", itemsStr);

  update(playerRef, {
    items: itemsStr,
  });
  // updateUserStats();
}

async function getGyro() {
  if (typeof DeviceMotionEvent.requestPermission === "function") {
    // Handle iOS 13+ devices.
    await DeviceMotionEvent.requestPermission()
      .then((state) => {
        if (state === "granted") {
          window.addEventListener("devicemotion", handleOrientation);
        } else {
          console.error("Request to access the orientation was rejected");
        }
      })
      .catch(console.error);
  } else {
    window.addEventListener("devicemotion", handleOrientation);
  }
}

export const getCookies = (cookieStr) =>
  cookieStr
    .split(";")
    .map((str) => str.trim().split(/=(.+)/))
    .reduce((acc, curr) => {
      acc[curr[0]] = curr[1];
      return acc;
    }, {});

export function setCookies(data) {
  var cookieString = "";
  for (let key in data) {
    cookieString += `${key}=${data[key]};`;
  }
  cookieString += " path=/;";
  console.log("COOKIE STRING", cookieString);
  document.cookie = cookieString;

  console.log("COOKIES AFTER", document.cookie);
}

// stuff here

let cubeRotate;

let timeStart = 0;
let timeProgress = 0;
let markerYOffset = 40;
let startedAnimations = false;
function init() {
  const d = new Date();
  timeStart = d.getTime();
  timeProgress = 0;
  startedAnimations = true;
}

// configuration of the custom layer for a 3D model per the CustomLayerInterface

var encounterScene = new SOURCE.Scene();
export var encounterMob = new SOURCE.Scene();
export let encounterCamera = new SOURCE.PerspectiveCamera(
  90,
  window.innerWidth / window.innerHeight,
  0.1,
  1000
);

let clientMouse = {};

document.onmousemove = handleMouseMove;
function handleMouseMove(event) {
  clientMouse.x = event.pageX;
  clientMouse.y = event.pageY;
}

export function checkPlayerClick() {
  var raycaster = new SOURCE.Raycaster(); // create once
  var mouse = new SOURCE.Vector2(); // create once

  mouse.x = (clientMouse.x / window.innerWidth) * 2 - 1;
  mouse.y = -(clientMouse.y / window.innerHeight) * 2 + 1;

  raycaster.setFromCamera(mouse, encounterCamera);

  var arr = [];

  console.log(encounterScene.children);
  var intersects = raycaster.intersectObject(sceneLayer["player"].scene);

  // for (let intersect in intersects){
  //     if (intersect.object == undefined) continue

  //     encounterScene.remove(intersect.object.parent)

  // }
  return intersects.length > 0;
}

export let randInt = function (lower, upper) {
  return Math.floor(Math.random() * (upper - lower)) + lower;
};

let rotation = { x: 0, y: 0, z: 0 };
let position = { x: 0, y: 0, z: 0 };

export let encounterControls;
export function getEncounterInfo(info) {
  return encounterInfo[info];
}
export function setEncounterInfo(info, value) {
  encounterInfo[info] = value;
}
export let encounterInfo = {};
let reticleEntity;

let reticleOpacity = 1;

let playerLayerNum = 30;

export var encounterRenderer;

let inEncounter = false;
let inCombat = false;

let hitbarMoving = true;

let animationFunction = undefined;

let animationInfo = {};

let previousPlayerStats;

function encounterUpdate() {
  if (!inEncounter) {
    // Idle aniamtion
    {
      // if (
      //   sceneLayer["player"] &&
      //   sceneLayer["player"].leftHand &&
      //   sceneLayer["player"].rightHand
      // ) {
      //   sceneLayer["player"].leftHand.position.x =
      //     Math.sin(timeProgress / 400.0) * 2;
      //   sceneLayer["player"].leftHand.position.y =
      //     10 + -Math.abs(Math.cos(timeProgress / 400.0)) * 2;
      //   sceneLayer["player"].rightHand.position.x =
      //     Math.sin(timeProgress / 400.0 + 1.6) * 2;
      //   sceneLayer["player"].rightHand.position.y =
      //     10 + -Math.abs(Math.cos(timeProgress / 400.0 + 1.6)) * 2;
      //   sceneLayer["player"].scene.position.y = Math.sin(
      //     timeProgress / 400.0 + 1.6
      //   );
      // }
    }
  } else {
    if (inCombat) {
      // Opacity of Reticle -----------

      let isHit = checkHit();

      let targetOpacity = isHit ? 0.8 : 0.2;

      if (targetOpacity != reticleEntity) {
        setOpacity(reticleEntity, targetOpacity);
        reticleOpacity = targetOpacity;
      }

      // ---------------------

      if (getPlayerTurn() && hitbarMoving) {
        let offset = encounterInfo.movement.pattern(
          timeProgress,
          sceneLayer[encounterMob].scene.position
        );
        sceneLayer[encounterMob].scene.position.set(
          offset.x,
          offset.y,
          offset.z
        );
        // hitBarElm.style.left = `${ (Math.sin(timeProgress/400)/2 + 0.5) *e 100}%`
      }
    }

    for (let entity in sceneLayer) {
      if (sceneLayer[entity].animationFunction) {
        let done = sceneLayer[entity].animationFunction(entity);
        if (done) sceneLayer[entity].animationFunction = undefined;
      }
    }

    if (reticleEntity) {
      // reticleEntity.rotation.set(encounterCamera.rotation.x ,encounterCamera.rotation.y,encounterCamera.rotation.z);
      // reticleEntity.position.set(encounterCamera.position.x + 0,encounterCamera.position.y + 0,encounterCamera.position.z - 50);
    }
  }

  // encounterControls.update();

  // var raycaster = new SOURCE.Raycaster(); // create once
  // var mouse = new SOURCE.Vector2(); // create once

  // mouse.x = ( clientMouse.x/ encounterRenderer.domElement.clientWidth ) * 2 - 1;
  // mouse.y = - ( clientMouse.y / encounterRenderer.domElement.clientHeight ) * 2 + 1;

  // raycaster.setFromCamera( mouse, encounterCamera );

  // var arr = []
  // var intersects = raycaster.intersectObjects( encounterScene.children );
  // console.log("INTERSECT " , intersects);
  // console.log(clientMouse, arr);

  if (previousPlayerStats != JSON.stringify(playerStats)) {
    updateStatsUi();
  }

  previousPlayerStats = JSON.stringify(playerStats);
  // modelOrigin[0] += 0.000001
  // updateModelTransform()
  // cubeRotate.rotation.y += 0.01;

  // render the updated scene and camera
  // source.renderer.render(source.scene, source.camera);
}

function startEntityAnimation(entity, func) {
  sceneLayer[entity].animationInfo = {};
  sceneLayer[entity].animationInfo.rotation = {
    ...sceneLayer[entity].scene.rotation,
  };
  sceneLayer[entity].animationInfo.position = {
    ...sceneLayer[entity].scene.position,
  };
  sceneLayer[entity].animationInfo.startingRot =
    sceneLayer[entity].scene.rotation.y;
  sceneLayer[entity].animationInfo.spinY =
    sceneLayer[entity].scene.rotation.y + 0.0;

  sceneLayer[entity].animationFunction = func;
}

function spinModel(entity) {
  sceneLayer[entity].animationInfo.spinY += (20 * Math.PI) / 180;
  console.log(sceneLayer[entity].animationInfo.spinY);
  sceneLayer[entity].scene.rotation.y = sceneLayer[entity].animationInfo.spinY;

  let condition =
    sceneLayer[entity].animationInfo.spinY >=
    sceneLayer[entity].animationInfo.startingRot + (360 * Math.PI) / 180;
  if (condition) {
    sceneLayer[entity].scene.rotation.y =
      sceneLayer[entity].animationInfo.startingRot;
  }
  return condition;
}

export function startSpinAnimation(name) {
  startEntityAnimation(name, spinModel);
}

export function playerDamageIndicate() {
  sceneLayer["player"].scene.remove(sceneLayer["player"].light);

  const ambientLight = new SOURCE.AmbientLight(0xff0000, 100);
  sceneLayer["player"].light = ambientLight;
  sceneLayer["player"].scene.add(ambientLight);

  setTimeout(function () {
    sceneLayer["player"].scene.remove(sceneLayer["player"].light);

    const ambientLight = new SOURCE.AmbientLight(0xffffff, 7);
    sceneLayer["player"].light = ambientLight;
    sceneLayer["player"].scene.add(ambientLight);
  }, 300);
}
export function checkHit() {
  var raycaster = new SOURCE.Raycaster(); // create once
  var mouse = new SOURCE.Vector2(); // create once

  mouse.x = 0.5 * 2 - 1;
  mouse.y = -0.5 * 2 + 1;

  raycaster.setFromCamera(mouse, encounterCamera);

  var arr = [];

  console.log(encounterScene.children);
  var intersects = raycaster.intersectObject(sceneLayer[encounterMob].scene);

  // for (let intersect in intersects){
  //     if (intersect.object == undefined) continue

  //     encounterScene.remove(intersect.object.parent)

  // }
  return intersects.length > 0;
}

function attackPlayer() {}
export function calculatePlayerDamage() {
  return Math.floor(Math.random() * 10);
}

function showEncounterResult(win) {
  // Show win to player ------------

  document.getElementsByTagName("result-back")[0].classList.add("resultShow");

  document.getElementById("resultWin").classList.remove("resultShow");
  document.getElementById("resultLose").classList.remove("resultShow");

  if (win) {
    document.getElementById("resultWin").classList.add("resultShow");
  } else {
    document.getElementById("resultLose").classList.add("resultShow");
  }

  setTimeout(function () {
    document
      .getElementsByTagName("result-back")[0]
      .classList.remove("resultShow");
  }, 2000);
}
export function playerWin() {
  setEncounter(false);
  deleteMapObject(sceneLayer[encounterMob].scene.name);

  let typeObject = sceneLayer[encounterMob].type;

  // if (entities[typeObject].collectable) {
  addItem(typeObject);
  // }

  console.log("TYPE OBJECT ", typeObject);

  // Show win to player ------------

  showEncounterResult(true);
}
export function playerLose() {
  setEncounter(false);
  deleteMapObject(sceneLayer[encounterMob].scene.name);

  let typeObject = sceneLayer[encounterMob].type;

  console.log("TYPE OBJECT ", typeObject);

  showEncounterResult(false);
}

async function loadMarkers() {
  await checkLoadTime();
  await getMarkerSync();
}
async function checkLoadTime() {
  const db = getDatabase();

  const day = new Date().getDate();
  console.log("NEW LAOD ", day);

  const infoRef = ref(db, "infoLoad");

  const snapshot = await get(infoRef);
  console.log("SNAP VAL", snapshot.val());

  const lastDay = snapshot.val().timestamp;

  if (lastDay != day) {
    set(ref(db, "infoLoad/"), {
      timestamp: day,
    });
    // ref(db, 'markers/').remove();

    remove(ref(db, "markers/"));
  }
}

async function getMarkerSync() {
  console.log("MARKER SYNC");

  const db = getDatabase();
  const markerRef = ref(db, "markers/");

  console.log(db, markerRef);

  onValue(markerRef, (snapshot) => {
    console.log("IN VAL");
    const data = snapshot.val();
    console.log("DATA", data);

    if (first) return;

    // first = true;

    for (let marker in data) {
      if (entities[data[marker].type] == undefined) continue;
      if (marker in sceneLayer) continue;
      let thisModel = entities[data[marker].type].model;
      console.log("DATA NOW", data[marker]);

      const loader2 = new GLTFLoader();
      loader2.load(thisModel, (gltf) => {
        let range = 500;

        let newCoord = [data[marker].long, data[marker].lat];
        createMapObject(marker, newCoord);

        addMarkerSync(marker, newCoord[0], newCoord[1]);

        let newObj = new SOURCE.Group();

        newObj.add(gltf.scene.clone());
        sceneLayer[marker].scene.add(newObj);

        const ambientLight = new SOURCE.AmbientLight(0xffffff, 7);

        sceneLayer[marker].scene.add(ambientLight);
        sceneLayer[marker].type = data[marker].type;
        sceneLayer[marker].location = newCoord;
        markers.push(marker);
      });
    }
  });
}

function finishPlayerLoad() {
  // encounterScene.add(sceneLayer["player"].scene);
}

function degreesToRadians(deg) {
  return (deg * Math.PI) / 180;
}

function handleOrientation(event) {
  const alphaChange = event.rotationRate.alpha / 50;
  const betaChange = event.rotationRate.beta / 50;
  const gammachange = event.rotationRate.gamma / 50;

  console.log(event);

  const xChange = event.acceleration.x * 10;
  const yChange = event.acceleration.y * 10;
  const zChange = event.acceleration.z * 10;

  rotation.x += alphaChange;
  rotation.y += betaChange;
  rotation.z += gammachange;

  position.x -= xChange;
  position.y -= yChange;
  position.z += zChange;

  // console.log("EVENT")
  // console.log(event)
  // Do stuff...

  // document.getElementById("request").innerHTML = rotation.x + "," +rotation.y + "," + rotation.z + ","
  console.log("HANDLE SET");
  let nowRot = rotation;

  encounterCamera.rotation.x = encounterCamera.origionalRot.x + rotation.x / 50;
  encounterCamera.rotation.y = encounterCamera.origionalRot.y + rotation.y / 50;
  encounterCamera.rotation.z = encounterCamera.origionalRot.z + rotation.z / 50;
}

export function updateStatsUi() {
  if (
    document.getElementById("healthInfo") &&
    document.getElementById("enemyHealth")
  ) {
    let img = document.createElement("img");
    img.src = heart;
    img.classList.add("encounter-heart");

    // Player Health
    document.getElementById("healthInfo").innerHTML = "";

    for (let i = 0; i < playerStats.health / 10; i++) {
      document.getElementById("healthInfo").appendChild(img.cloneNode(true));
    }

    let playerIconImg = document.createElement("img");
    playerIconImg.src = playerIcon;
    playerIconImg.classList.add("player-icon");

    document.getElementById("healthInfo").appendChild(playerIconImg);

    // Enemy Health
    document.getElementById("enemyHealth").innerHTML = "";

    for (let i = 0; i < encounterInfo.health / 10; i++) {
      document.getElementById("enemyHealth").appendChild(img.cloneNode(true));
    }

    let enemyIcon = document.createElement("img");
    enemyIcon.src = encounterInfo.icon;
    enemyIcon.classList.add("enemy-icon");

    console.log("UPDATE INFO HERE", encounterInfo, encounterInfo.icon);
    document.getElementById("enemyHealth").appendChild(enemyIcon);
  }
}
function updateUserStats() {
  setCookies(playerStats);
}

export function setHealth(num) {
  playerStats.health = `${num}`;
  updateUserStats();
}

function getHealth() {
  return playerStats.health;
}

function arraysEqual(a1, a2) {
  /* WARNING: arrays must not contain {objects} or behavior may be undefined */
  return JSON.stringify(a1) == JSON.stringify(a2);
}

function deleteMapObject(id) {
  console.log("MAP OBJ DEL ID", id);
  map.current.removeLayer("3d-model-" + id);

  const db = getDatabase();
  const markerRef = ref(db, "markers/" + id);
  remove(markerRef);

  // sceneLayer[markers[i]].origin[1]

  const index = markers.indexOf(id);
  if (index > -1) {
    // only splice array when item is found
    console.log("SUCESSFUL SPLICE", markers);
    markers.splice(index, 1); // 2nd parameter means remove one item only
    console.log("SUCESSFUL SPLICE AF", markers);
  }
}
function createMapObject(id, [long, lat]) {
  let objectOrigin = [lat, long];
  let objectAltitude = 0;
  let objectRotate = [Math.PI / 2, 0, 0];
  let objectAsMercatorCoordinate = mapboxgl.MercatorCoordinate.fromLngLat(
    objectOrigin,
    objectAltitude
  );
  let objectTransform = {
    translateX: objectAsMercatorCoordinate.x,
    translateY: objectAsMercatorCoordinate.y,
    translateZ: objectAsMercatorCoordinate.z,
    rotateX: objectRotate[0],
    rotateY: objectRotate[1],
    rotateZ: objectRotate[2],
    /* Since the 3D object is in real world meters, a scale transform needs to be
     * applied since the CustomLayerInterface expects units in MercatorCoordinates.
     */
    scale: objectAsMercatorCoordinate.meterInMercatorCoordinateUnits(),
  };

  // const canvas = document.querySelector(".mapboxgl-canvas");
  // let context = canvas.getContext("webgl");

  // console.log("CONTEXT",context)

  let mapObjectScene = {
    renderer: new SOURCE.WebGLRenderer({
      canvas: document.querySelector(".mapboxgl-canvas"),
      // context: gl,
      antialias: true,
    }),
    scene: "",
    camera: "",
  };
  sceneLayer[id] = {
    scene: "",
    origin: objectOrigin,
    setOrigin: function ([long, lat]) {
      this.origin = [long, lat];

      objectAltitude = 0;
      objectRotate = [Math.PI / 2, 0, 0];

      objectAsMercatorCoordinate = mapboxgl.MercatorCoordinate.fromLngLat(
        this.origin,
        objectAltitude
      );

      // transformation parameters to position, rotate and scale the 3D object onto the map
      objectTransform = {
        translateX: objectAsMercatorCoordinate.x,
        translateY: objectAsMercatorCoordinate.y,
        translateZ: objectAsMercatorCoordinate.z,
        rotateX: objectRotate[0],
        rotateY: objectRotate[1],
        rotateZ: objectRotate[2],
        /* Since the 3D object is in real world meters, a scale transform needs to be
         * applied since the CustomLayerInterface expects units in MercatorCoordinates.
         */
        scale: objectAsMercatorCoordinate.meterInMercatorCoordinateUnits(),
      };
    },
  };

  let newLayer = {
    id: "3d-model-" + id,
    type: "custom",
    renderingMode: "3d",
    onAdd: function (map, gl) {
      this.camera = new SOURCE.Camera();
      mapObjectScene.camera = this.camera;
      sceneLayer[id].scene = new SOURCE.Scene();
      mapObjectScene.scene = sceneLayer[id].scene;
      sceneLayer[id].scene.name = id;
      sceneLayer[id].scene.scale.set(1.2, 1.2, 1.2);

      this.map = map;

      // use the Mapbox GL JS map canvas for three.js
      this.renderer = mapObjectScene.renderer;

      this.renderer.autoClear = false;
    },
    render: function (gl, matrix) {
      const rotationX = new SOURCE.Matrix4().makeRotationAxis(
        new SOURCE.Vector3(1, 0, 0),
        objectTransform.rotateX
      );
      const rotationY = new SOURCE.Matrix4().makeRotationAxis(
        new SOURCE.Vector3(0, 1, 0),
        objectTransform.rotateY
      );
      const rotationZ = new SOURCE.Matrix4().makeRotationAxis(
        new SOURCE.Vector3(0, 0, 1),
        objectTransform.rotateZ
      );

      const m = new SOURCE.Matrix4().fromArray(matrix);
      const l = new SOURCE.Matrix4()
        .makeTranslation(
          objectTransform.translateX,
          objectTransform.translateY,
          objectTransform.translateZ
        )
        .scale(
          new SOURCE.Vector3(
            objectTransform.scale,
            -objectTransform.scale,
            objectTransform.scale
          )
        )
        .multiply(rotationX)
        .multiply(rotationY)
        .multiply(rotationZ);

      this.camera.projectionMatrix = m.multiply(l);
      this.renderer.resetState();
      this.renderer.render(sceneLayer[id].scene, this.camera);
      this.map.triggerRepaint();
    },
  };

  map.current.addLayer(newLayer, "waterway-label");
}

function addModel(model, scene, source = undefined) {
  const gltfLoader = new GLTFLoader();
  gltfLoader.load(model, (gltf) => {
    let newObj = new SOURCE.Group();

    newObj.add(gltf.scene.clone());
    scene.add(newObj);

    if (source) {
      source = newObj;
    }
  });
}

function generateMarkerTimer() {
  mapTimer = setInterval(function () {
    let numNear = 0;
    for (let marker of markers) {
      if (playerStats.location == undefined) continue;
      if (
        playerStats.location.length < 2 ||
        sceneLayer[marker].location.length < 2
      )
        continue;

      let distance = measure(
        playerStats.location[0],
        playerStats.location[1],
        sceneLayer[marker].location[1],
        sceneLayer[marker].location[0]
      );

      if (distance < mapAreaRange) numNear++;
    }

    if (numNear < numEntitesNear) {
      addRandomEntity();
    }
  }, 1000);
}

function updateVisuals() {
  requestAnimationFrame(updateVisuals);
  gameUpdate();
  // encounterControls.update();
  encounterRenderer.render(encounterScene, encounterCamera);
  renderChest();
}

export function updatePlayerLooks() {
  const loader = new GLTFLoader();

  loader.load(helmetModel, (gltf) => {
    let newObj = new SOURCE.Group();
    newObj.position.set(0, 0, 0);

    newObj.add(gltf.scene.clone());
    newObj.scale.set(3, 3, 3);
    newObj.position.set(0, 10, 0);
    sceneLayer["player"].scene.add(newObj);
    playerObjects.push(newObj);

    console.log("CHANGING " + playerObjects.length);
  });
}

export function MapQuest() {
  // After Page Load
  React.useEffect(() => {
    console.log("MAP USE EFF ", map.current);

    // map = {};
    if (map.current) {
      return;
    }

    console.log("USE EFFECT LOAD");
    map.current = new mapboxgl.Map({
      container: "newMap", // container ID
      style: "mapbox://styles/judithricketts/clsg7exxp005901queyczbt3o", // style URL
      // center: [-74.5, 40], // starting position [lng, lat]

      pitch: 45,
      bearing: -10, // bearing in degrees
      zoom: 17.5, // starting zoom
      //  style:{ width: "100%", height: "100%" }
      center: [-0.15283301046573883, 50.825023956653],
      // center:[148.9819, -35.3981],
    });

    // if (map.current) return;

    geolocate = new mapboxgl.GeolocateControl({
      fitBoundsOptions: {
        zoom: 17.5,
        pitch: 45,
        bearing: -10,
      },
      positionOptions: {
        enableHighAccuracy: true,
      },
      // When active the map will receive updates to the device's location as it changes.
      trackUserLocation: true,
      // Draw an arrow next to the location dot to indicate which direction the device is heading.
      showUserHeading: true,
    });
    map.current.addControl(geolocate);

    // Add the NavigationControl here
    // map.current.addControl(new NavigationControl(), "top-left");

    loadUserStats();
    setHealth(30);

    updateStatsUi();

    let i = 0;

    var encounterCanvas = document.getElementById("encounterCanvas");

    encounterRenderer = new SOURCE.WebGLRenderer({
      antialias: true,
      canvas: encounterCanvas,
      alpha: true,
    });

    encounterControls = new OrbitControls(
      encounterCamera,
      encounterRenderer.domElement
    );

    encounterRenderer.setSize(window.innerWidth, window.innerHeight);
    encounterRenderer.setClearColor(0x000000, 0);

    geolocate.on("geolocate", function (e) {
      let facing = document.getElementsByClassName("mapboxgl-user-location")[0];

      console.log("GEO", e);
      let targetCoord = [e.coords.longitude, e.coords.latitude];

      playerStats.location = targetCoord;

      playerStats.loadLocation = targetCoord;

      console.log(
        "DIST BEFORE",
        playerStats.walkDist,
        playerStats.firstGeolocate
      );

      if (
        playerStats.walkDist != undefined &&
        playerStats.previousGeolcate != undefined &&
        !arraysEqual(playerStats.previousGeolcate, targetCoord)
      ) {
        playerStats.walkDist +=
          measure(
            targetCoord[0],
            targetCoord[1],
            sceneLayer["player"].origin[0],
            sceneLayer["player"].origin[1]
          ) *
          (1 / 0.75);
        console.log(
          "WALK DIST,",
          playerStats.walkDist,
          targetCoord,
          sceneLayer["player"].origin
        );
        updatePedoMeter(playerStats.walkDist);
      }

      playerStats.previousGeolcate = [e.coords.longitude, e.coords.latitude];

      let smoothGoTo = setInterval(function () {
        let thisCoord = [
          sceneLayer["player"].origin[0],
          sceneLayer["player"].origin[1],
        ];

        let difference = [
          targetCoord[0] - thisCoord[0],
          targetCoord[1] - thisCoord[1],
        ];
        if (difference[0] < 0.000001 && difference[1] < 0.000001) {
          clearInterval(smoothGoTo);
          // console.log("CLEARED")
        }
        // console.log("DIF " + difference,thisCoord)

        sceneLayer["player"].setOrigin([
          sceneLayer["player"].origin[0] + difference[0] * 0.1,
          sceneLayer["player"].origin[1] + difference[1] * 0.1,
        ]);
      }, 20);

      let markersTouching = getMarkersOn(
        [e.coords.longitude, e.coords.latitude],
        distanceInteract
      );

      console.log("MARKER 0,", sceneLayer[markers[0]]);

      console.log("TOUCHING", markersTouching);

      for (let i = 0; i < markers.length; i++) {
        sceneLayer[markers[i]].info = { selected: false };
      }

      for (let i = 0; i < markersTouching.length; i++) {
        sceneLayer[markersTouching[i]].info = { selected: true };
      }

      if (markersTouching.length > 0) {
        document.getElementsByTagName("engage-btn")[0].classList.remove("hide");
        document
          .getElementsByTagName("engage-btn")[0]
          .setAttribute("fighting", markersTouching[i]);
        // setEncounter(true,entities[  sceneLayer[markersTouching[i]].type],markersTouching[i]);
        // setEncounter(true,entities[  "leaf"],"marker-18");
        // getGyro();
        // setTimeout(function(){
        //     setEncounter(false)
        // },2000)
      } else {
        document.getElementsByTagName("engage-btn")[0].classList.add("hide");
      }
      // if (window.DeviceOrientationEvent) {
      //     window.addEventListener("deviceorientation", function () {
      //         tilt([event.beta, event.gamma]);
      // }, true);
      // sceneLayer["player"].setOrigin([e.coords.longitude,e.coords.latitude])

      // map.current.removeLayer('waterway-label')

      // setTimeout(function(){
      //     map.current.addLayer(createPlayerLayer("id-" + playerLayerNum), 'here');

      //     c

      //     updatePlayerTransform();
      // },300)
    });

    map.current.on("style.load", () => {
      map.current.on("click", function (e) {
        // console.log();

        let targetCoord = [e.lngLat.lng, e.lngLat.lat];

        console.log("TARGET COORD", targetCoord);
        console.log("PLAYER LOC", playerStats.location);

        // mapClick(e);

        let markersNear = getNearestMarker(targetCoord);

        console.log("NEAR", markersNear, sceneLayer[markersNear.name]);
        if (markersNear.dist < 100) {
          getRoute(playerStats.location, sceneLayer[markersNear.name].origin);
        }
      });

      loadMarkers();

      setTimeout(function () {
        generateMarkerTimer();
      }, 3000);

      createMapObject("player", [50.82144184830019, -0.15006820239463078]);

      const loader = new GLTFLoader();
      let modelScene;
      console.log("ADD PLAYER MODEL");
      loader.load(playerBase, (gltf) => {
        let newObj = new SOURCE.Group();
        newObj.position.set(0, 0, 0);

        newObj.add(gltf.scene.clone());
        newObj.scale.set(3, 3, 3);
        newObj.position.set(0, 10, 0);
        sceneLayer["player"].scene.add(newObj);
        playerObjects.push(newObj);

        console.log("CHANGING " + playerObjects.length);
      });

      console.log("ADD PLAYER MODEL");
      loader.load(playerHandLeft, (gltf) => {
        let newObj = new SOURCE.Group();
        newObj.position.set(0, 0, 0);

        newObj.add(gltf.scene.clone());
        newObj.scale.set(3, 3, 3);
        newObj.position.set(0, 10, 0);
        sceneLayer["player"].scene.add(newObj);
        sceneLayer["player"].leftHand = newObj;
        playerObjects.push(newObj);

        console.log("CHANGING " + playerObjects.length);
      });

      loader.load(playerHandRight, (gltf) => {
        let newObj = new SOURCE.Group();
        newObj.position.set(0, 0, 0);

        newObj.add(gltf.scene.clone());
        newObj.scale.set(3, 3, 3);
        newObj.position.set(0, 10, 0);
        sceneLayer["player"].scene.add(newObj);
        sceneLayer["player"].rightHand = newObj;
        playerObjects.push(newObj);

        console.log("CHANGING " + playerObjects.length);

        finishPlayerLoad();
      });

      updatePlayerLooks();

      const ambientLight = new SOURCE.AmbientLight(0xffffff, 7);

      sceneLayer["player"].scene.add(ambientLight);
      sceneLayer["player"].light = ambientLight;
      init();

      // Insert the layer beneath any symbol layer.
      const layers = map.current.getStyle().layers;
      const labelLayerId = layers.find(
        (layer) => layer.type === "symbol" && layer.layout["text-field"]
      ).id;

      map.current.addLayer(
        {
          id: "add-3d-buildings",
          source: "composite",
          "source-layer": "building",
          filter: ["==", "extrude", "true"],
          type: "fill-extrusion",
          minzoom: 15,
          paint: {
            "fill-extrusion-color": "#aaa",

            // Use an 'interpolate' expression to
            // add a smooth transition effect to
            // the buildings as the user zooms in.
            "fill-extrusion-height": [
              "interpolate",
              ["linear"],
              ["zoom"],
              15,
              0,
              15.05,
              ["get", "height"],
            ],
            "fill-extrusion-base": [
              "interpolate",
              ["linear"],
              ["zoom"],
              15,
              0,
              15.05,
              ["get", "min_height"],
            ],
            "fill-extrusion-opacity": 0.6,
          },
        },
        labelLayerId
      );
      console.log("MAKE BASE");
      //   make_base();
    });

    loadMap(map.current);

    map.current.on("load", function () {
      geolocate.trigger();
    });

    // const btn = document.getElementById( "request" );
    // btn.addEventListener( "click", function(){ setEncounter(true)} );

    // const leaveBtn = document.getElementById("leave-encounter");
    // leaveBtn.addEventListener("click", function () {
    //   playerWin();
    // });

    document
      .getElementById("item-popup")
      .addEventListener("click", function (e) {
        e.stopPropagation();
      });

    const engage = document.getElementsByTagName("engage-btn")[0];

    engage.addEventListener("click", function (e) {
      let fightingVal = engage.getAttribute("fighting");

      console.log("ENGAGE CLICK", fightingVal);

      if (sceneLayer[fightingVal]) {
        setEncounter(true, entities[sceneLayer[fightingVal].type], fightingVal);
        // setEncounter(true, entities["leaf"], fightingVal);
      }
    });

    // function mapClick(event) {

    //   // var raycaster = new SOURCE.Raycaster(); // create once
    //   // var mouse = new SOURCE.Vector2(); // create once

    //   // mouse.x = (clientMouse.x / window.innerWidth) * 2 - 1;
    //   // mouse.y = -(clientMouse.y / window.innerHeight) * 2 + 1;

    //   // raycaster.setFromCamera(mouse, encounterCamera);

    //   // var arr = [];

    //   // console.log(encounterScene.children);
    //   // var intersects = raycaster.intersectObject(sceneLayer["player"].scene);

    //   // -------------------

    //   event.preventDefault();
    //   //I had to change the changedTouches to point to adapt
    //   //  to the incoming event object as for me there was no such property

    //   var raycaster = new SOURCE.Raycaster(); // create once
    //   let mouse = {};
    //   mouse.x = (event.point.x / window.innerWidth) * 2 - 1;
    //   mouse.y = -(event.point.y / window.innerHeight) * 2 + 1;

    //   const camInverseProjection = new SOURCE.Matrix4().getInverse(
    //     this.camera.projectionMatrix
    //   );
    //   const cameraPosition = new SOURCE.Vector3().applyMatrix4(
    //     camInverseProjection
    //   );
    //   const mousePosition = new SOURCE.Vector3(
    //     mouse.x,
    //     mouse.y,
    //     1
    //   ).applyMatrix4(camInverseProjection);
    //   const viewDirection = mousePosition
    //     .clone()
    //     .sub(cameraPosition)
    //     .normalize();

    //   raycaster.set(cameraPosition, viewDirection);

    //   //no change from here on
    //   var intersects = raycaster.intersectObjects(scene.children, true);
    //   console.log("Here", intersects);
    //   if (intersects.length > 0) {
    //     alert("hi");
    //     console.log("Intersection:", intersects[0].object.name == "");
    //   }
    // }

    // Encounter Scene

    // document.body.appendChild( renderer.domElement );

    updateVisuals();
  });

  return (
    <div id="map-parent">
      <map-container id="map"></map-container>

      {/* <canvas id="bg"></canvas> */}
      <engage-btn class="hide">Engage</engage-btn>
      <canvas id="logo"></canvas>
      <canvas id="mapOver"></canvas>

      <Encounter />

      <Inventory />
      <Pedometer />

      <result-back>
        <result-box id="resultWin">
          <result-text>Item added!</result-text>
        </result-box>
        <result-box id="resultLose">
          <result-text>Encounter lost!</result-text>
        </result-box>
      </result-back>
    </div>
  );
}
